exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-image-slider-js": () => import("./../../../src/pages/imageSlider.js" /* webpackChunkName: "component---src-pages-image-slider-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-item-js": () => import("./../../../src/pages/menuItem.js" /* webpackChunkName: "component---src-pages-menu-item-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-modules-js": () => import("./../../../src/pages/modules.js" /* webpackChunkName: "component---src-pages-modules-js" */)
}

